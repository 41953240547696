(function() {

  // Get the side bar
  const sideBar = document.getElementById("collapseSidebar");

  // Get the side bar toggle button
  const toggleSideBarButton = document.getElementById("sidebar-toggle");

  // Helper method for setting sideBar state to open or close
  function openCloseSidebar(state) {
    if(state == "open") {
      // Set the side bar to the open state
      // Remove the closed state class of the side bar
      sideBar.classList.remove("closed")
      sideBar.classList.add("open")

      // Set Direction of sidebar toggler
      toggleSideBarButton.innerHTML = "<span class='fa-sharp fa-thin fa-circle-chevron-left'></span>";

      // Save state to local storage
      sessionStorage.setItem("sidebar-session-state", "open");
    } else {
      // Set the side bar to the cloed state
      // Remove the open state class of the side bar
      sideBar.classList.remove("open")
      sideBar.classList.add("closed")

      // Set Direction of sidebar toggler
      toggleSideBarButton.innerHTML = "<span class='fa-sharp fa-thin fa-circle-chevron-right'></span>";

      // Save state to local storage
      sessionStorage.setItem("sidebar-session-state", "closed");
    }
  }

  // Event that is triggered when the user clicks the sideBar Toggle Button
  function sideBarToggleButtonEvent(evt) {
    // If the sideBar is currently open, toggle the new state
    let sideBarStateEvt = sideBar.classList.contains("open") ? "closed" : "open";

    // Toggle sideBar style
    openCloseSidebar(sideBarStateEvt)
  }

  // Check state of side bar
  const sideBarSessionState = sessionStorage.getItem("sidebar-session-state")

  // The side bar defaults to the closed state
  // But if we have a saved state from local storage, use that
  if(sideBar){
    if(sideBarSessionState) {
      openCloseSidebar(sideBarSessionState)
    } else {
      // Default sidebar (if there is one) to closed
      sideBar.classList.add("closed")
    }
  }

  // Add event listener on click for the sideBar Toggle Button
  if (toggleSideBarButton) {
    toggleSideBarButton.addEventListener("click", sideBarToggleButtonEvent);
  }

})();
